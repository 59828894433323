import React, { useState } from 'react';
import { Box, Typography, Link, Modal } from '@mui/material';
import { FaFacebook, FaInstagram, FaTiktok, FaWhatsapp, FaEnvelope, FaPhone } from 'react-icons/fa';
import { motion } from 'framer-motion';
import LegalNotice from './legalNotice';
import TermsOfSale from './termsOfSales';

const Footer = () => {
  const [openLegal, setOpenLegal] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);

  const socialLinks = [
    { icon: <FaFacebook size={24} />, href: 'https://www.facebook.com/share/16Bzdpqc7N/?mibextid=wwXIfr' },
    { icon: <FaInstagram size={24} />, href: 'https://www.instagram.com/traiteur_du_soleil_' },
    { icon: <FaTiktok size={24} />, href: 'https://tiktok.com' },
    { icon: <FaWhatsapp size={24} />, href: 'https://wa.me/+33785853636' },
  ];

  const handleOpenLegal = () => setOpenLegal(true);
  const handleCloseLegal = () => setOpenLegal(false);
  const handleOpenTerms = () => setOpenTerms(true);
  const handleCloseTerms = () => setOpenTerms(false);

  return (
    <Box component="footer" role="contentinfo" sx={{ backgroundColor: '#fae6c6', padding: '24px', textAlign: 'center', color: '#555' }}>
      {/* Liens sociaux */}
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '16px' }}>
        {socialLinks.map((link, index) => (
          <motion.div
            key={index}
            whileHover={{ scale: 1.2, rotate: 10 }}
            whileTap={{ scale: 0.9 }}
          >
            <Link href={link.href} target="_blank" rel="noopener noreferrer" sx={{ color: '#555' }}>
              {link.icon}
            </Link>
          </motion.div>
        ))}
      </Box>

      {/* Liens vers Mentions Légales et CGV */}
      <Box sx={{ marginBottom: '16px' }}>
        <Link
          component="button"
          onClick={handleOpenLegal}
          sx={{ color: '#555', textDecoration: 'underline', marginRight: '20px' }}
          aria-label="Ouvrir les mentions légales"
        >
          Mentions Légales
        </Link>
        <Link
          component="button"
          onClick={handleOpenTerms}
          sx={{ color: '#555', textDecoration: 'underline' }}
          aria-label="Ouvrir les conditions générales de vente"
        >
          Conditions Générales de Vente
        </Link>
      </Box>

      {/* Adresse e-mail et numéro de téléphone */}
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '16px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <FaEnvelope size={18} />
          <Typography variant="body2">
            <Link href="mailto:contact@paindusoleil.com" sx={{ color: '#555', textDecoration: 'none' }}>
              contact@paindusoleil.com
            </Link>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <FaPhone size={18} />
          <Typography variant="body2">
            <Link href="tel:+33785853636" sx={{ color: '#555', textDecoration: 'none' }}>
              +33 7 85 85 36 36
            </Link>
          </Typography>
        </Box>
      </Box>

      {/* Copyright */}
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <Typography variant="body2">© 2025 Pain du Soleil. Tous droits réservés.</Typography>
      </motion.div>

      {/* Modale pour Mentions Légales */}
      <Modal
        open={openLegal}
        onClose={handleCloseLegal}
        aria-labelledby="modal-legal-title"
        aria-describedby="modal-legal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            boxShadow: 24,
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
            borderRadius: '8px',
          }}
        >
          <LegalNotice />
        </Box>
      </Modal>

      {/* Modale pour CGV */}
      <Modal
        open={openTerms}
        onClose={handleCloseTerms}
        aria-labelledby="modal-terms-title"
        aria-describedby="modal-terms-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            boxShadow: 24,
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
            borderRadius: '8px',
          }}
        >
          <TermsOfSale />
        </Box>
      </Modal>
    </Box>
  );
};

export default Footer;