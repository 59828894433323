import React from 'react';
import { Typography, Box, Divider } from '@mui/material';

const TermsOfSale = () => {
  return (
    <Box
      sx={{
        padding: { xs: '16px', md: '40px' },
        maxWidth: '900px',
        margin: '0 auto',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: '#2c3e50', fontWeight: 'bold', textAlign: 'center' }}
      >
        Conditions Générales de Vente
      </Typography>
      <Divider sx={{ mb: 4 }} />

      <Typography variant="h5" gutterBottom sx={{ color: '#34495e', mt: 3 }}>
        Préambule
      </Typography>
      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Les présentes conditions de vente régissent les relations entre d'une part, Pain du Soleil, Société par Actions Simplifiée à associé unique au capital de 117 500 €, située au 12 rue Robert Esnault-Pelterie - 95240 Cormeilles en Parisis, et d'autre part, toute personne se connectant au site internet www.paindusoleil.com www.paindusoleil.fr pour y effectuer un achat, ci-après dénommée "le client". Ces conditions visent à définir les modalités et conditions des ventes réalisées entre Pain du Soleil et le client. Elles s'appliquent exclusivement aux achats effectués sur ce site, à l'exclusion des conditions applicables en magasin. Toute commande adressée à Pain du Soleil sur le site www.paindusoleil.com www.paindusoleil.fr implique l'acceptation pleine et entière des présentes conditions. Le site est la propriété exclusive de Pain du Soleil, qui se réserve la possibilité d'adapter ou de modifier à tout moment ces conditions, modifications devant être portées à la connaissance du client par mise à jour en ligne. Toute reproduction, intégrale ou partielle, est soumise à autorisation, bien que les liaisons de type hypertexte vers le site soient autorisées sans demande spécifique.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ color: '#34495e', mt: 3 }}>
        1. Acceptation des conditions
      </Typography>
      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Le client reconnaît avoir pris connaissance, au moment de la passation de commande, des conditions particulières de vente affichées sur cet écran et déclare les accepter sans réserve. Les présentes conditions générales régissent la relation contractuelle entre Pain du Soleil et le client, lesquelles prévaudront sur toute autre condition, sauf dérogation préalable et écrite.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ color: '#34495e', mt: 3 }}>
        2. Produits
      </Typography>
      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        En se connectant sur le site www.paindusoleil.com www.paindusoleil.fr, le client se voit proposer l'achat de divers produits de la marque Pain du Soleil, ainsi que des produits de sociétés partenaires, conditionnés et livrés dans des emballages adéquats. Ces produits sont conformes aux normes et à la législation en vigueur en France. Les photographies illustrant les produits sont fournies à titre indicatif. En cas de différence entre le produit reçu et sa présentation, la responsabilité de Pain du Soleil ne pourra être engagée. Certains produits, disponibles dans notre entrepôt, peuvent être temporairement en rupture de stock, rendant leur achat impossible.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ color: '#34495e', mt: 3 }}>
        3. Commande
      </Typography>
      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Les systèmes d'enregistrement automatique sont considérés comme valant preuve de la nature, du contenu et de la date de la commande. Pain du Soleil confirme l'acceptation de la commande au client à l'adresse mail communiquée. La vente sera considérée comme conclue dès la confirmation de la commande par nos soins. Pain du Soleil se réserve le droit d'annuler une commande en cas de litige ou d'anomalie constatée lors de sa passation. Les informations fournies par le client engagent ce dernier : en cas d'erreur dans les coordonnées du destinataire, le vendeur ne saurait être tenu responsable de l'impossibilité de livraison. Avant la conclusion de la commande, l’acheteur est informé de la nature et de la quantité des produits commandés, de leurs prix respectifs et des frais de port correspondants. La commande, confirmée par e-mail, fera l'objet d'une facturation et sera réglée par le client selon les modalités convenues en dehors du site. Il est recommandé de conserver ces informations sur un support papier ou informatique. Toute commande sera honorée dans la limite des stocks disponibles et des délais de livraison indiqués.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ color: '#34495e', mt: 3 }}>
        4. Livraison
      </Typography>
      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Les conditions de livraison sont les suivantes : forfait de 15 € TTC pour une commande inférieure à 15 kg, et forfait de 20 € TTC pour une commande entre 15 et 30 kg. Les livraisons s'effectuent uniquement en France métropolitaine (à l'exclusion de la Corse et des DOM-TOM). L'expédition de la commande intervient une fois que le règlement intégral des produits et des frais de transport a été effectué par le client, selon les modalités convenues. La livraison est effectuée par un transporteur (La Poste ou autre) et, dès réception du colis par le transporteur, un délai maximal de 72 heures ouvrées est prévu pour la livraison. Il est conseillé, dans la mesure du possible, de faire livrer la commande à son lieu de travail. En cas d'absence, le transporteur laissera un avis de passage et les réserves éventuelles (concernant l'intégrité du colis) devront être formulées dans les 48 heures par lettre recommandée avec accusé de réception et communiquées par e-mail à Pain du Soleil. À défaut de notification dans ce délai, le client sera réputé accepter la livraison. En cas d'erreur de préparation de commande, la rectification se fera aux frais de Pain du Soleil. La livraison peut être effectuée en plusieurs fois, le client ne réglant qu'une seule fois les frais de port.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ color: '#34495e', mt: 3 }}>
        5. Droit de Rétractation
      </Typography>
      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Le client non satisfait dispose d'un délai de 14 jours (cachet de La Poste faisant foi) à compter de la réception des produits pour exercer son droit de rétractation, sans avoir à justifier de motif ni à payer de pénalités. Les frais de retour sont à la charge du client. Passé ce délai, le droit de retour ne s'applique plus. Pour exercer ce droit, le client devra notifier son intention de se rétracter à Pain du Soleil soit par téléphone au 01.34.12.20.01 (du lundi au vendredi, de 8h30 à 17h), soit par mail (info@paindusoleil.com) ou par courrier à l'adresse suivante : 
        <br /><br />
        PAIN DU SOLEIL<br />
        10-12 Rue Robert Esnault Pelterie<br />
        95240 Cormeilles en Parisis<br /><br />
        Conformément à l’article L.221-23 du Code de la Consommation, le client doit renvoyer les produits dans leur emballage d'origine, non ouvert, en parfait état, accompagnés d'une copie de la facture, et ce, au plus tard 14 jours après l'envoi du formulaire de rétractation. Les produits ouverts pour des raisons d'hygiène ne pourront être repris. Le client accepte qu'un contrôle soit effectué avant le remboursement, qui interviendra dans un délai de 30 jours à compter de la réception des produits conformes.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ color: '#34495e', mt: 3 }}>
        6. Prix
      </Typography>
      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Les prix sont exprimés en euros et affichés toutes taxes comprises (T.T.C.), hors frais de port. Ils peuvent être révisés en fonction d'évènements indépendants de la volonté de Pain du Soleil. Le prix indiqué lors de la commande est définitif et en vigueur au moment de la commande, incluant la TVA pour la France et les pays de la CEE. Ce prix inclut le coût des produits ainsi que les frais de manutention, d'emballage, de conservation et de transport.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ color: '#34495e', mt: 3 }}>
        7. Litiges
      </Typography>
      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Le présent contrat est soumis au droit français. Pain du Soleil ne pourra être tenu responsable des dommages, matériels ou immatériels, résultant d'une mauvaise utilisation des produits. La responsabilité de Pain du Soleil est limitée au montant de la commande. En cas de difficulté, le client pourra rechercher une solution amiable, sans préjudice des délais légaux. En cas de litige, il est recommandé de contacter d'abord l'entreprise, puis, si nécessaire, de recourir au Service du Médiateur MEDIAVET (http://www.mediavet.net).
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ color: '#34495e', mt: 3 }}>
        8. Garantie
      </Typography>
      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Pain du Soleil garantit que tous les pains sélectionnés sont fabriqués en France. Pour les produits alimentaires, les dates de consommation doivent être respectées par le client. En aucun cas, Pain du Soleil ne pourra être tenu responsable du non-respect des dispositions réglementaires en vigueur dans le pays de réception. La garantie est limitée à la valeur du produit à la date de vente, sans recours contre la marque ou le fabricant. Le client bénéficie de la garantie légale d'éviction et des vices cachés (Art. 1625 et suivants du Code Civil), sous réserve d'en apporter la preuve dans un délai raisonnable.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ color: '#34495e', mt: 3 }}>
        9. Clause de réserve de propriété
      </Typography>
      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Conformément à la loi du 12 mai 1980 (n°80-335), Pain du Soleil se réserve expressément la propriété des produits livrés jusqu'au règlement intégral du prix et des frais. Le règlement s'entend comme l'encaissement effectif du montant total de la commande (produits et transport) sur le compte de Pain du Soleil.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ color: '#34495e', mt: 3 }}>
        10. Traitement des données personnelles
      </Typography>
      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Le renseignement des informations nominatives collectées aux fins de la vente à distance est obligatoire pour le traitement et l'acheminement des commandes, ainsi que pour l'établissement des factures. Le défaut de renseignement entraîne l'annulation de la commande. Conformément à la loi "Informatique et Libertés" (article 34 de la loi du 6 janvier 1978), le client dispose d'un droit d'accès, de modification, de rectification et de suppression des données le concernant, à exercer auprès de Pain du Soleil. Pour ce faire, il suffit de se connecter à « Mon compte » en ligne ou de nous contacter par mail (info@paindusoleil.com). Pain du Soleil s'engage à ne pas communiquer les coordonnées de ses clients à des tiers, sauf accord préalable. Conformément à l’article L.223-2 du Code de la consommation, le client peut également s’inscrire sur une liste d’opposition au démarchage téléphonique disponible sur le site www.bloctel.gouv.fr.
      </Typography>
    </Box>
  );
};

export default TermsOfSale;
