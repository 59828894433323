import React from 'react';
import { Typography, Box, Divider } from '@mui/material';

const LegalNotice = () => {
  return (
    <Box
      sx={{
        padding: { xs: '16px', md: '40px' },
        maxWidth: '900px',
        margin: '0 auto',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: '#2c3e50', fontWeight: 'bold', textAlign: 'center' }}
      >
        Mentions Légales
      </Typography>
      <Divider sx={{ mb: 4 }} />

      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Le site est édité par la société :
      </Typography>

      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Société et capital : Pain Du Soleil SASU au capital de 117 500 €<br />
        Responsable : Gaby DAHAN<br />
        Adresse : 10-12 rue Robert Esnault Pelterie - 95240 Cormeilles en Parisis<br />
        Téléphone : 01 34 12 20 01<br />
        E-mail : info@paindusoleil.com<br />
        Site web : www.paindusoleil.com<br />
        TVA intra-communautaire : FR 17 478 607 443<br />
        Siret : 478 607 443 00031<br />
        Code APE/NAF : 1071A / Fabrication industrielle de pain et de pâtisserie fraîche
      </Typography>

      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Directeur de la Publication : M. G. DAHAN<br />
        Téléphone : 01 39 59 85 23 Fax : 01 39 14 28 85<br />
        Adresse email : info@paindusoleil.com
      </Typography>

      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Conception : BRX Software & Invest<br />
        Site internet : www.drivesoft.fr<br />
        Contact : Florent Breux<br />
        Adresse email : contact@drivesoft.fr
      </Typography>

      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Hébergement : Le site est hébergé par la société OVH<br />
        Contact Technique : Florent Breux<br />
        Adresse email : contact@drivesoft.fr
      </Typography>

      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Conformément à la loi "Informatique et Liberté" n° 78 - 17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, nous nous engageons à informer les personnes qui fournissent des données nominatives sur notre site de leurs droits, notamment de leur droit d’accès et de rectification sur ces données. Nous nous engageons à prendre toutes précautions afin de préserver la sécurité de ces informations et empêcher qu’elles ne soient déformées, endommagées ou communiquées à des tiers.
      </Typography>

      <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, color: '#555' }}>
        Photographies des produits : © Pain du Soleil - Copie et cession interdites. Tous droits réservés
      </Typography>
    </Box>
  );
};

export default LegalNotice;