// App.js
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ThemeProvider } from '@mui/material/styles';
import Header from './components/Header';
import Footer from './components/Footer';
import { Box } from '@mui/material';
import theme from './pages/createTheme';

const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Products = lazy(() => import('./pages/Products'));
const Location = lazy(() => import('./pages/Location'));
const Contact = lazy(() => import('./pages/Contact'));
const Success = lazy(() => import('./pages/Success'));

const App = () => {
  return (
    <ParallaxProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <Box component="main" sx={{ flexGrow: 1, paddingTop: '64px' }}>
              <Suspense fallback={<div>Chargement...</div>}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/notre-histoire" element={<About />} />
                  <Route path="/nos-produits" element={<Products />} />
                  <Route path="/localisation" element={<Location />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/success" element={<Success />} />
                </Routes>
              </Suspense>
            </Box>
            <Footer />
          </Box>
        </Router>
      </ThemeProvider>
    </ParallaxProvider>
  );
};

export default App;
