// theme.js ou dans createTheme()
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: "'Host Grotesk', sans-serif",
  },
});

export default theme;
