import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { FaInfoCircle, FaShoppingCart, FaMapMarkerAlt, FaBars, FaTimes, FaFacebook, FaInstagram, FaTiktok, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Header = ({ onOpenMenu }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navItems = [
    { text: 'Notre histoire', icon: <FaInfoCircle /> },
    { text: 'Nos produits', icon: <FaShoppingCart /> },
    { text: 'Localisation', icon: <FaMapMarkerAlt /> },
  ];

  return (
    <AppBar component="header" position="fixed" sx={{ backgroundColor: '#fae6c6', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' }}>
      <Toolbar sx={{ justifyContent: 'space-between', maxWidth: '1200px', width: '100%', margin: 'auto', paddingLeft: '0px' }}>
        {/* Logo et CustomButton (desktop) */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: '30px', md: '100px' } }}>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <motion.img
                src="/images/soleil.webp"
                alt="Logo Pain du Soleil"
                loading="lazy"
                width="30"
                height="30"
                style={{
                  width: { xs: '20px', md: '30px' },
                  height: 'auto',
                }}
                initial={{ rotate: 0 }}
                animate={{ rotate: 360 }}
                transition={{ duration: 2, repeat: Infinity, ease: 'linear' }}
              />
              <Typography
                variant="h6"
                sx={{
                  color: '#8B4513',
                  fontWeight: 'bold',
                  fontSize: { xs: '1rem', md: '1.5rem' },
                }}
              >
                Pain du Soleil
              </Typography>
            </Box>
          </Link>
        </Box>

        {/* Navigation desktop */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
          {navItems.map((item, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.1, color: '#8B4513' }}
              whileTap={{ scale: 0.9 }}
              style={{ display: 'inline-block' }}
            >
              <Button
                color="inherit"
                href={`/${item.text.toLowerCase().replace(' ', '-')}`}
                sx={{
                  color: '#333',
                  fontWeight: 'bold',
                  mx: 1,
                  '&:hover': { backgroundColor: '#E0E0E0' },
                }}
                startIcon={item.icon}
              >
                {item.text}
              </Button>
            </motion.div>
          ))}
          <Link to="/contact" style={{ textDecoration: 'none' }}>
            <ContactButton />
          </Link>
        </Box>

        {/* Menu hamburger pour mobile */}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton onClick={toggleMenu} sx={{ color: '#8B4513' }} aria-label="Ouvrir le menu de navigation">
            <motion.div animate={{ rotate: isMenuOpen ? 180 : 0 }} transition={{ duration: 0.5 }}>
              {isMenuOpen ? <FaTimes /> : <FaBars />}
            </motion.div>
          </IconButton>
        </Box>
      </Toolbar>

      {/* Menu mobile plein écran */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              zIndex: 1000,
              backgroundColor: '#fae6c6',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* Bouton de fermeture en haut à droite */}
            <Box sx={{ position: 'absolute', top: '16px', right: '16px' }}>
              <IconButton onClick={toggleMenu} sx={{ color: '#8B4513' }} aria-label="Fermer le menu">
                <FaTimes size={30} />
              </IconButton>
            </Box>

            {/* Logo de la société en haut, centré */}
            <Box sx={{ position: 'absolute', top: '16px', left: '50%', transform: 'translateX(-50%)' }}>
              <img src="/images/logo.webp" alt="Logo Pain du Soleil" loading="lazy" width="120" height="120" style={{ width: '120px' }} />
            </Box>

            {/* Liens de navigation */}
            {navItems.map((item, index) => (
              <Link
                key={index}
                to={`/${item.text.toLowerCase().replace(' ', '-')}`}
                style={{ textDecoration: 'none', width: '100%', textAlign: 'center' }}
                onClick={toggleMenu}
              >
                <Button
                  color="inherit"
                  sx={{
                    color: '#333',
                    fontWeight: 'bold',
                    my: 1,
                    width: '100%',
                    padding: '10px 0',
                    '&:hover': { backgroundColor: '#E0E0E0' },
                  }}
                  startIcon={item.icon}
                >
                  {item.text}
                </Button>
              </Link>
            ))}

            {/* Bouton Contact centré */}
            <Link
              to="/contact"
              style={{ textDecoration: 'none', width: '100%', textAlign: 'center' }}
              onClick={toggleMenu}
            >
              <ContactButton />
            </Link>

            {/* Boutons des réseaux sociaux */}
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '100px' }}>
              <motion.div whileHover={{ scale: 1.2, rotate: 10 }} whileTap={{ scale: 0.9 }}>
                <Link to="https://facebook.com" target="_blank" rel="noopener noreferrer" style={{ color: '#555' }}>
                  <FaFacebook size={24} />
                </Link>
              </motion.div>
              <motion.div whileHover={{ scale: 1.2, rotate: 10 }} whileTap={{ scale: 0.9 }}>
                <Link to="https://instagram.com" target="_blank" rel="noopener noreferrer" style={{ color: '#555' }}>
                  <FaInstagram size={24} />
                </Link>
              </motion.div>
              <motion.div whileHover={{ scale: 1.2, rotate: 10 }} whileTap={{ scale: 0.9 }}>
                <Link to="https://tiktok.com" target="_blank" rel="noopener noreferrer" style={{ color: '#555' }}>
                  <FaTiktok size={24} />
                </Link>
              </motion.div>
              <motion.div whileHover={{ scale: 1.2, rotate: 10 }} whileTap={{ scale: 0.9 }}>
                <Link to="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer" style={{ color: '#555' }}>
                  <FaWhatsapp size={24} />
                </Link>
              </motion.div>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </AppBar>
  );
};

// Bouton de contact stylé
const ContactButton = () => {
  return (
    <StyledContactWrapper>
      <button aria-label="Contacter Pain du Soleil">
        <div className="svg-wrapper-1">
          <div className="svg-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}>
              <path fill="none" d="M0 0h24v24H0z" />
              <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z" />
            </svg>
          </div>
        </div>
        <span>Contact</span>
      </button>
    </StyledContactWrapper>
  );
};

const StyledContactWrapper = styled.div`
  button {
    font-family: 'Roboto, sans-serif';
    font-size: 18px;
    background: #8B4513;
    color: white;
    padding: 0.6em 1em;
    padding-left: 0.9em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.2s;
    cursor: pointer;
    margin-left: 10px;
    @media (max-width: 600px) {
      margin: 0 auto;
      display: inline-flex;
    }
  }

  button span {
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
  }

  button svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
  }

  button:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
  }

  button:hover svg {
    transform: translateX(1.2em) rotate(45deg) scale(1.1);
  }

  button:hover span {
    transform: translateX(5em);
  }

  button:active {
    transform: scale(0.95);
  }

  @keyframes fly-1 {
    from { transform: translateY(0.1em); }
    to { transform: translateY(-0.1em); }
  }
`;

export default Header;